import { Controller } from 'stimulus'
import { useDispatch } from 'stimulus-use';

export default class extends Controller {
  static targets = ['maxPlayers', 'region', 'province', 'year', 'playerInput', 'container', 'ranking'];

  connect() {
    useDispatch(this);
  }

  async selectRanking(e) {
    const filter_used = e.target.getAttribute("data-target")
    if (filter_used == 'dashboard.province') { 
      this.regionTarget.value = ''
    } else if (filter_used == 'dashboard.region') {
      this.provinceTarget.value = ''
    }
    const query = `ranking=${this.rankingTarget.value}&year=${this.yearTarget.value}&player=${this.playerInputTarget.value}&max=${this.maxPlayersTarget.value}&province=${this.provinceTarget.value}&region=${this.regionTarget.value}`;
    const response = await fetch('/ranking?' + query, { headers: { accept: 'application/json' } });
    if (response.ok) {
      const data = await response.json()
      this.containerTarget.innerHTML = data['html_data'];
      this.dispatch('rankingSelected');
    }
  }
}