import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['winsLosses', 'barChart', 'statsChart'];

    connect() {
      this.colors=['#FBEC51', '#0091D2'];
      this.fontColor='#666';
      this.drawCanvases();
    }

    drawCanvases(){
      if (this.hasWinsLossesTarget) {
        this.drawWinLossesCharts();
      }
      if (this.hasBarChartTarget) {
        this.drawBarChart();
      }
      if (this.hasStatsChartTarget) {
        this.drawStatsChart();
      }
    }

    drawStatsChart(){
      const canvases = this.statsChartTargets;
      const tickHeight = 0.075;
      const percHeight = tickHeight;
      const padding = 0.07 * window.innerWidth;
      const legendSpace = 3 * tickHeight;
      
      let maxFactor = 30;
      let adjFactor = 0.10;
      let rescale = false;
       
      // Check whether the graphs need to be rescaled (too much difference between min and max for low rankings)
      let globalMin = parseFloat(canvases[0].parentElement.dataset.min)
      let globalMax = parseFloat(canvases[0].parentElement.dataset.max)
      const globalLowPerc = parseFloat(canvases[0].parentElement.dataset.lowperc)
      const globalHighPerc = parseFloat(canvases[0].parentElement.dataset.highperc)
      const globalIQR = globalHighPerc - globalLowPerc
     
      //if ((globalMax / globalMin) >= maxFactor) {
        rescale = true;
        globalMax = globalHighPerc + (globalIQR * adjFactor);
        globalMin = globalLowPerc - (globalIQR * adjFactor);
      //}

      let fullWidth = globalMax - globalMin;

      canvases.forEach((canvas) => {
        const ratio = Math.ceil(window.devicePixelRatio)
        canvas.width = canvas.scrollWidth * ratio;
        canvas.height = canvas.scrollHeight * ratio;

        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = false;
        ctx.lineWidth = 2 + 0.0025 * window.innerWidth;

        // Get Data
        let minPoints = parseFloat(canvas.previousElementSibling.dataset.min);
        let maxPoints = parseFloat(canvas.previousElementSibling.dataset.max);
        let avgPoints = parseFloat(canvas.previousElementSibling.dataset.avg);
        let lowPerc = parseFloat(canvas.previousElementSibling.dataset.lowperc);
        let highPerc = parseFloat(canvas.previousElementSibling.dataset.highperc);
        
        let playerPoints = 0
        if (canvas.previousElementSibling.hasAttribute('data-points')) {
          playerPoints = parseFloat(canvas.previousElementSibling.dataset.points);
        }

        if (rescale) {
          minPoints = (lowPerc - (fullWidth * adjFactor));
          maxPoints = (highPerc + (fullWidth * adjFactor));
          if (playerPoints > maxPoints && playerPoints <= globalMax)  {
            maxPoints = playerPoints;
          }

          if (playerPoints < minPoints && playerPoints != 0 && playerPoints >= globalMin) {
            minPoints = playerPoints;
          }
        }

        // Draw Results 
        const canvasActualWidth = canvas.width - padding * 2;
        
        let rankingWidth = maxPoints - minPoints;
        let minVsGlobalMin = minPoints - globalMin;
       
        // Draw General Shape
        if (rescale) {
          if (minPoints < playerPoints || playerPoints < globalMin) {
            this.drawTick(ctx, padding + (minVsGlobalMin / fullWidth) * canvasActualWidth, canvas.height / 2, tickHeight, canvas, '', '', legendSpace);
          } else {
            this.drawTick(ctx, padding + (minVsGlobalMin / fullWidth) * canvasActualWidth, canvas.height / 2, tickHeight, canvas, 'Points', parseInt(playerPoints), legendSpace);
          }
          if (maxPoints > playerPoints || playerPoints > globalMax) {
            this.drawTick(ctx, padding + ((minVsGlobalMin  + rankingWidth) / fullWidth) * canvasActualWidth, canvas.height / 2, tickHeight, canvas, '', '', legendSpace);
          } else {
            this.drawTick(ctx, padding + ((minVsGlobalMin  + rankingWidth) / fullWidth) * canvasActualWidth, canvas.height / 2, tickHeight, canvas, 'Points', parseInt(playerPoints), legendSpace);
          }
          this.drawLine(ctx, padding + (minVsGlobalMin / fullWidth) * canvasActualWidth, padding + ((minVsGlobalMin  + rankingWidth) / fullWidth) * canvasActualWidth, canvas.height / 2, canvas.height / 2);
        } else {
          this.drawTick(ctx, padding + (minVsGlobalMin / fullWidth) * canvasActualWidth, canvas.height / 2, tickHeight, canvas, 'Min', parseInt(minPoints), legendSpace);
          this.drawTick(ctx, padding + ((minVsGlobalMin  + rankingWidth) / fullWidth) * canvasActualWidth, canvas.height / 2, tickHeight, canvas, 'Max', parseInt(maxPoints), legendSpace);
          this.drawLine(ctx, padding + (minVsGlobalMin / fullWidth) * canvasActualWidth, padding + ((minVsGlobalMin  + rankingWidth) / fullWidth) * canvasActualWidth, canvas.height / 2, canvas.height / 2);
        }
        
        // Draw Average Tick
        if (!rescale) {
          this.drawTick(ctx, padding + ((minVsGlobalMin  + (avgPoints - minPoints)) / fullWidth) * canvasActualWidth, canvas.height / 2, tickHeight, canvas, 'Moyenne', parseInt(avgPoints), legendSpace);
        }

        // Draw Points Tick
        if (canvas.previousElementSibling.hasAttribute('data-points') && parseFloat(playerPoints) >= parseFloat(globalMin)) {
          if (playerPoints < maxPoints && playerPoints > minPoints) {
            if (rescale) {
              this.drawTick(ctx, padding + ((minVsGlobalMin  + (playerPoints - minPoints)) / fullWidth) * canvasActualWidth, canvas.height / 2, tickHeight, canvas, 'Points', parseInt(playerPoints), legendSpace / 1.4);
            } else {
              this.drawTick(ctx, padding + ((minVsGlobalMin  + (playerPoints - minPoints)) / fullWidth) * canvasActualWidth, canvas.height / 2, tickHeight, canvas, 'Points', parseInt(playerPoints), legendSpace);
            }
          }
        }

        // Draw Percentile
        if (rescale) {
          this.drawPercLine(ctx, padding + ((minVsGlobalMin  + (lowPerc - minPoints)) / fullWidth) * canvasActualWidth, canvas.height / 2, percHeight, canvas, '25%', parseInt(lowPerc), legendSpace);
          this.drawPercLine(ctx, padding + ((minVsGlobalMin  + (highPerc - minPoints)) / fullWidth) * canvasActualWidth, canvas.height / 2, percHeight, canvas, '75%', parseInt(highPerc), legendSpace);
        } else {
          this.drawPercLine(ctx, padding + ((minVsGlobalMin  + (lowPerc - minPoints)) / fullWidth) * canvasActualWidth, canvas.height / 2, percHeight, canvas, '', '25%', legendSpace);
          this.drawPercLine(ctx, padding + ((minVsGlobalMin  + (highPerc - minPoints)) / fullWidth) * canvasActualWidth, canvas.height / 2, percHeight, canvas, '', '75%', legendSpace);
        }
      })
    }

    drawBarChart(){
      const colors = this.colors.reverse();

      const canvases = this.barChartTargets;
      canvases.forEach((canvas) => {
        canvas.width = canvas.scrollWidth;
        canvas.height = canvas.scrollHeight;
        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = false;

        const data = Array.from(canvas.parentElement.previousElementSibling.children);
        const padding = 30;
        
        // Get Max Value
        const expresion = /^\w{1}:\s{1}(\d+)/
        let maxValue = 0;
        data.forEach((ranking) => {
          for(let i=1;i<ranking.getElementsByTagName("li").length;i++){
            maxValue = Math.max(maxValue, ranking.getElementsByTagName("li")[i].innerHTML.match(expresion)[1]);
          }
        })
        
        // Redimension to Add Padding
        const canvasActualHeight = canvas.height - padding * 2;
        const canvasActualWidth = canvas.width - padding * 2;
      
        // For each ranking
        let barIndex = 0;
        let spaceBetweenRankings = 0;
        const barSize = canvasActualWidth / ((data.length * 2) + (data.length - 1));
        

        data.forEach((ranking) => {
          // Structure Data
          let seriesData = {
            "victory": ranking.getElementsByTagName("li")[1].innerHTML.match(expresion)[1],
            "defeat": ranking.getElementsByTagName("li")[2].innerHTML.match(expresion)[1]
          };

          // Draw the Bars
          for (let categ in seriesData){
              var val = seriesData[categ];
              var barHeight = Math.round(canvasActualHeight * val/maxValue);

              if (barHeight == 0) {
                barHeight = 1;
              }

              this.drawBar(
                  ctx,
                  padding + spaceBetweenRankings + barIndex * barSize,
                  canvas.height - barHeight - padding,
                  barSize,
                  barHeight,
                  colors[barIndex%colors.length]
              );
                
              // Bar Number on Top
              ctx.save();
              ctx.textBaseline="bottom";
              ctx.textAlign="center";
              ctx.fillStyle = this.fontColor;
              ctx.font = "bold 20px Work Sans";
              ctx.fillText(ranking.getElementsByTagName("li")[(barIndex%colors.length)+1].innerHTML.match(expresion)[1], padding + spaceBetweenRankings + barIndex * barSize + barSize/2, canvas.height - barHeight - padding);
              ctx.restore();  

              barIndex++;
          }

          // Draw series name
          ctx.save();
          ctx.textBaseline="bottom";
          ctx.textAlign="center";
          ctx.fillStyle = this.fontColor;
          ctx.font = "bold 20px Work Sans";
          ctx.fillText(ranking.getElementsByTagName("li")[0].innerHTML, padding + spaceBetweenRankings + (barIndex-1) * barSize, canvas.height);
          ctx.restore();  

          spaceBetweenRankings+=barSize;
        })
      })
    }

    drawLine(ctx, yInit, yFinal, xInit, xFinal) {
      ctx.beginPath();
      ctx.strokeStyle = this.colors[0]
      ctx.moveTo(yInit, xInit);
      ctx.lineTo(yFinal, xFinal);
      ctx.stroke()
    }

    drawTick(ctx, upperLeftCornerX, upperLeftCornerY, tickHeight, canvas, legendName, legendValue, legendSpace) {
      ctx.beginPath();
      ctx.strokeStyle = this.colors[0]
      ctx.moveTo(upperLeftCornerX, upperLeftCornerY);
      ctx.lineTo(upperLeftCornerX, upperLeftCornerY - (tickHeight * canvas.height));
      ctx.moveTo(upperLeftCornerX, upperLeftCornerY);
      ctx.lineTo(upperLeftCornerX, upperLeftCornerY + (tickHeight * canvas.height));
      ctx.stroke()

      ctx.textBaseline="center";
      ctx.textAlign="center";
      ctx.fillStyle = this.colors[1]
      ctx.font = `bold ${18 + (0.0045 * window.innerWidth)}px Work Sans`;

      if (legendName == 'Points') {
        ctx.fillText(legendName, upperLeftCornerX, upperLeftCornerY + (tickHeight * canvas.height) - (legendSpace * canvas.height) * 1.40);
        ctx.fillText(legendValue, upperLeftCornerX, upperLeftCornerY + (tickHeight * canvas.height) + (legendSpace * canvas.height) * 1.15);
      } else if (legendName == 'Min' || legendName == 'Max' || legendName == 'Max (Limité)') {
        ctx.fillText(legendName, upperLeftCornerX, upperLeftCornerY + (tickHeight * canvas.height) - (legendSpace * canvas.height) * 2.05);
        ctx.fillText(legendValue, upperLeftCornerX, upperLeftCornerY + (tickHeight * canvas.height) + (legendSpace * canvas.height) * 1.78);
      } else {
        ctx.fillText(legendName, upperLeftCornerX, upperLeftCornerY + (tickHeight * canvas.height) - (legendSpace * canvas.height) * 0.85);
        ctx.fillText(legendValue, upperLeftCornerX, upperLeftCornerY + (tickHeight * canvas.height) + (legendSpace * canvas.height) * 0.60);
      }
    }

    drawPercLine(ctx, upperLeftCornerX, upperLeftCornerY, tickHeight, canvas, legendName, legendValue, legendSpace) {
      ctx.beginPath();
      ctx.strokeStyle = this.colors[1]
      ctx.moveTo(upperLeftCornerX, upperLeftCornerY);
      ctx.lineTo(upperLeftCornerX, upperLeftCornerY - (tickHeight * canvas.height));
      ctx.moveTo(upperLeftCornerX, upperLeftCornerY);
      ctx.lineTo(upperLeftCornerX, upperLeftCornerY + (tickHeight * canvas.height));
      ctx.stroke()

      ctx.textBaseline="center";
      ctx.textAlign="center";
      ctx.fillStyle = this.colors[1]
      ctx.font = `bold ${18 + (0.0045 * window.innerWidth)}px Work Sans`;

      ctx.fillText(legendName, upperLeftCornerX, upperLeftCornerY + (tickHeight * canvas.height) - (legendSpace * canvas.height) * 2.05);
      ctx.fillText(legendValue, upperLeftCornerX, upperLeftCornerY + (tickHeight * canvas.height) + (legendSpace * canvas.height) * 1.78);
    }

    drawBar(ctx, upperLeftCornerX, upperLeftCornerY, width, height,color){
      ctx.save();
      ctx.fillStyle=color;
      ctx.fillRect(upperLeftCornerX,upperLeftCornerY,width,height);
      ctx.restore();
    }

    drawWinLossesCharts(){
      const canvases = this.winsLossesTargets;
      canvases.forEach((canvas) => {
        const ratio = Math.ceil(window.devicePixelRatio)
        canvas.width = canvas.scrollWidth * ratio;
        canvas.height = canvas.scrollHeight * ratio;
        let ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = false;
        let values=[parseInt(canvas.nextElementSibling.lastElementChild.innerHTML.replace('%', '')),
                    parseInt(canvas.previousElementSibling.lastElementChild.innerHTML.replace('%', ''))];
        let arcWidth= canvas.width/7;
        this.drawDonutChart(ctx, canvas.height/2, canvas.width/2, canvas.width/2 - arcWidth, arcWidth,values);
      })
    }

    drawDonutChart(ctx, cx,cy,radius,arcwidth,values){
      let tot=0;
      let accum=0;
      const PI=Math.PI;
      const PI2=PI*2;
      const offset=-PI/2;
      ctx.lineWidth=arcwidth;
      for(var i=0;i<values.length;i++){tot+=values[i];}
      for(var i=0;i<values.length;i++){
          ctx.beginPath();
          ctx.arc(cx,cy,radius,
              offset+PI2*(accum/tot),
              offset+PI2*((accum+values[i])/tot)
          );
          ctx.strokeStyle=this.colors[i];
          ctx.stroke();
          accum+=values[i];
      }
  }
}