import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['output', 'round', 'points', 'type', 'tournament', 'category', 'nbr_participants', 'ranking'];

  connect() {
  }

  typeHandler() {
    if (this.typeTarget.value == 'tournament') {
      this.fieldOnOff([this.tournamentTarget], true);
      this.fieldOnOff([this.categoryTarget, this.nbr_participantsTarget], false);
    } else if (this.typeTarget.value == 'category') {
      this.fieldOnOff([this.tournamentTarget], false);
      this.fieldOnOff([this.categoryTarget, this.nbr_participantsTarget], true);
    } else if (this.typeTarget.value == '') {
      this.fieldOnOff([this.tournamentTarget, this.categoryTarget, this.nbr_participantsTarget], false);
    }
  } 

  fieldOnOff(fields, on){
    fields.forEach((field, i)  => {
      if (on) {
        field.classList.remove('visibility-off');
        field.classList.add('visibility-on');
        field.children[1].disabled = false
      } else {
        field.classList.remove('visibility-on');
        field.classList.add('visibility-off');
        field.children[1].disabled = true
      }
    });
  }

  tournamentRound(){
    const round = this.tournamentTarget.innerText.split('- ')[5];
    let options = this.roundTarget.children;
    for (var i = 0; i < options.length; i++) {
      if (round == options[i].innerHTML) {
        options[i - 1].setAttribute('selected', true);
      };
    }
  }

  async submitForm(e) {
    e.preventDefault();
    const query = `type=${this.typeTarget.value}&tournament=${this.tournamentTarget.children[1].value}&category=${this.categoryTarget.children[1].value}&ranking=${this.rankingTarget.value}&round=${this.roundTarget.value}&nbr_participants=${this.nbr_participantsTarget.children[1].value}`
    const response = await fetch('/simulator?' + query, {headers: { accept: 'application/json' } });
    if (response.ok) {
      debugger;
      const data = await response.json()
      this.outputTarget.innerHTML = data['html_data'];
    }
  }
}