import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['year', 'playerInput', 'currentPlayerResults', 'container'];

  async selectGames() {
    const query = `year=${this.yearTarget.value}&player=${this.playerInputTarget.value}`;
    const response = await fetch('/games?' + query, { headers: { accept: 'application/json' } });
    if (response.ok) {
      const data = await response.json()
      this.containerTarget.innerHTML = data['html_data'];
    }
  }

  async selectGamesYear() {
    const query = `year=${this.yearTarget.value}&player=${this.currentPlayerResultsTarget.innerHTML}`;
    const response = await fetch('/games?' + query, { headers: { accept: 'application/json' } });
    if (response.ok) {
      const data = await response.json()
      this.containerTarget.innerHTML = data['html_data'];
    }
  }

  async selectGamesOpponent(e) {
    const query = `year=${this.yearTarget.value}&player=(${e.target.dataset.number})`;
    const response = await fetch('/games?' + query, { headers: { accept: 'application/json' } });
    if (response.ok) {
      const data = await response.json()
      this.containerTarget.innerHTML = data['html_data'];
    }
  }

  async selectStats() {
    const query = `year=${this.yearTarget.value}&player=${this.playerInputTarget.value}`;
    const response = await fetch('/stats?' + query, { headers: { accept: 'application/json' } });
    if (response.ok) {
      const data = await response.json()
      this.containerTarget.innerHTML = data['html_data'];
    }
  }

  async selectComparisons() {
    const query = `year=${this.yearTarget.value}&player=${this.playerInputTarget.value}`;
    const response = await fetch('/compare?' + query, { headers: { accept: 'application/json' } });
    if (response.ok) {
      const data = await response.json()
      this.containerTarget.innerHTML = data['html_data'];
    } 
  }

  async selectRankings() {
    const query = `year=${this.yearTarget.value}&player=${this.playerInputTarget.value}`;
    const response = await fetch('/ranking?' + query, { headers: { accept: 'application/json' } });
    if (response.ok) {
      const data = await response.json()
      this.containerTarget.innerHTML = data['html_data'];
    } 
  }

}