import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['weddingCategory', 'assistance', 'guestAccommodation', 'bus', 'comments'];

  toggle(e) {
    const selectedCategory = e.currentTarget.innerText
    this.weddingCategoryTargets.forEach((category) => {
      if (selectedCategory == category.children[0].children[0].innerText) {
        category.classList.add('visibility-on');
        category.classList.remove('visibility-off');
        category.scrollIntoView({ behavior: "smooth" });
      } else {
        category.classList.add('visibility-off');
        category.classList.remove('visibility-on');
      } 
    });
  }

  formHandler(e) {
    if (e.currentTarget.dataset.target == 'wedding.assistance') {
      if (e.currentTarget.children[0].children[1].checked == true) {
        this.busTarget.classList.add('visibility-on')
        this.busTarget.classList.remove('visibility-off')
        this.busTarget.children[0].children[0].disabled = false
        this.busTarget.children[0].children[1].disabled = false
        this.commentsTarget.classList.add('visibility-on')
        this.commentsTarget.classList.remove('visibility-off')
        this.commentsTarget.children[1].disabled = false
      } else {
        this.busTarget.classList.add('visibility-off')
        this.busTarget.classList.remove('visibility-on')
        this.busTarget.children[0].children[0].disabled = true
        this.busTarget.children[0].children[1].disabled = true
        this.commentsTarget.classList.add('visibility-off')
        this.commentsTarget.classList.remove('visibility-on')
        this.commentsTarget.children[1].disabled = true
      }
    } else if (e.currentTarget.dataset.target == 'wedding.bus') {
      if (e.currentTarget.children[0].children[1].checked == true) {
        this.guestAccommodationTarget.classList.add('visibility-on')
        this.guestAccommodationTarget.classList.remove('visibility-off')
        this.guestAccommodationTarget.children[1].disabled = false
      } else {
        this.guestAccommodationTarget.classList.add('visibility-off')
        this.guestAccommodationTarget.classList.remove('visibility-on')
        this.guestAccommodationTarget.children[1].disabled = true
      }
    }
  }
}