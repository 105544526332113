import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['comment'];

  toggleComments() {
    this.commentTarget.classList.toggle('show');
  }

  autoResize(e) {
    e.currentTarget.style.height = 'auto';
    e.currentTarget.style.height = (e.currentTarget.scrollHeight) + 'px';
  }

}