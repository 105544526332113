import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['club', 'list', 'category', 'date', 'game_date', 'interclub', 'tournament', 'division', 'round'];
    
    toggle(event) {
      if (event.currentTarget.dataset.gameType == 'interclub') {
        this.interclubSelected();
      } else if (event.currentTarget.dataset.gameType == 'tournament') {
        this.tournamentSelected();
      }
    }

    interclubSelected() {
      this.interclubTarget.classList.remove('visibility-off');
      this.tournamentTarget.classList.add('visibility-off');
      this.roundTarget.classList.add('visibility-off');
      
      this.divisionTarget.disabled = false;

      this.clubTarget.value = '';
      this.categoryTarget.innerHTML = '';
      this.categoryTarget.disabled = true;
      this.dateTarget.innerHTML = '';
      this.dateTarget.disabled = true;

      this.roundTarget.innerHTML = '';
      this.roundTarget.disabled = true;
    }

    tournamentSelected() {
      this.interclubTarget.classList.add('visibility-off');
      this.tournamentTarget.classList.remove('visibility-off');
      this.roundTarget.classList.remove('visibility-off');

      this.divisionTarget.value = '';
      this.divisionTarget.disabled = true;
      
      this.toggleCascadingTournament();
      this.dateTarget.disabled = true;
      this.roundTarget.disabled = true;
    }

    toggleCascadingTournament() {
      const validDate = this.validGameDate();
      if (validDate) {
        this.clubTarget.disabled = true; 
        this.clubTarget.value = '';
        this.categoryTarget.innerHTML = '';
        this.categoryTarget.disabled = true;
        this.dateTarget.innerHTML = '';
        this.dateTarget.disabled = true;
      } else {
        this.clubTarget.disabled = false; 
      }
    }

    validGameDate() {
      const dateRegex = /^(20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/;
      if (!(dateRegex.test(this.game_dateTarget.value))) {
        return true;
      } else {
        return false;
      } 
    }

    async autofill(event) {
      let type = (event.currentTarget != this.categoryTarget) ? 'club' : 'category';
      let club_id = 0

      // Find data-value 
      let options = this.listTarget.children;
      for (var i = 0; i < options.length; i++) {
        if (this.clubTarget.value == options[i].innerHTML) {
          options[i].setAttribute('selected', true);
          club_id = options[i].dataset.value;
        };
      }

      if (club_id == 0) {
        return;
      }

      let query = `date=${this.game_dateTarget.value}&club=${club_id}&category=${this.categoryTarget.value}&type=${type}&ctype=single`;
      let response = await fetch('/tournaments?' + query, { headers: { accept: 'application/json' } });
      if (response.ok) {
        let data = await response.json()
        if (type == 'club') {
          this.categoryTarget.disabled = false;
          this.categoryTarget.innerHTML = data.join();
          this.dateTarget.disabled = true;
          this.dateTarget.innerHTML = '';
          if (data.length == 2) {
            type = 'category';
            query = `date=${this.game_dateTarget.value}&club=${club_id}&category=${this.categoryTarget.value}&type=${type}&ctype=single`;
            response = await fetch('/tournaments?' + query, { headers: { accept: 'application/json' } });
            if (response.ok) {
              let data = await response.json();
              this.dateTarget.disabled = false;
              this.dateTarget.innerHTML = data.join();
            }
          }
        } else if (type == 'category') {
          this.dateTarget.disabled = false;
          this.dateTarget.innerHTML = data.join();
        }
      } else {
        this.dateTarget.disabled = true;
        this.dateTarget.innerHTML = '';
        if (type == 'club') {
          this.categoryTarget.disabled = true;
          this.categoryTarget.innerHTML = '';
        }
      }
    }
}
