import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'
import { useDispatch } from 'stimulus-use';

export default class extends Controller {
  static targets = ['input', 'datalist'];

  connect() {
    useClickOutside(this);
    useDispatch(this);
    this.currentFocus = -1;
  }

  filterOptions() {
    this.datalistTarget.classList.add('show');
    const text = this.inputTarget.value.toUpperCase();
    let options = this.datalistTarget.children;
    for (var i = 0; i < options.length; i++) {
      if (options[i].innerHTML.toUpperCase().indexOf(text) != -1) {
        options[i].classList.add('show');
      } else {
        options[i].classList.remove('show');
      };
    }
  }

  showOptions() {
    this.datalistTarget.classList.add('show');
  }

  clickOutside(event) {
    this.datalistTarget.classList.remove('show');
    this.currentFocus = -1;
  }

  selectOption(event) {
    this.inputTarget.value = event.currentTarget.innerHTML
    this.datalistTarget.classList.remove('show');
    this.dispatch('inputModified');
  }

  keyboardSelect(event) {
    const options = Array.from(this.datalistTarget.children).filter(option => option.classList.contains("show"))
    if (event.keyCode == 40) {
      this.currentFocus++
      this.addActive(options);
    } else if(event.keyCode == 38) {
      this.currentFocus--
      this.addActive(options);
    } else if(event.keyCode == 13) {
      event.preventDefault();
      if (this.currentFocus > -1) {
        if (options) {
          options[this.currentFocus].click();
        }
      }
    }
  }

  addActive(options) {
    if (!options) return false;
    this.removeAllActive(options);
    if (this.currentFocus >= options.length) {
      this.currentFocus = 0;
    } else if (this.currentFocus < 0) {
      this.currentFocus = (options.length - 1);
    }
    options[this.currentFocus].classList.add("active");
    options[this.currentFocus].scrollIntoViewIfNeeded(false);
  }

  removeAllActive(options) {
    for (var i = 0; i < options.length; i++) {
      options[i].classList.remove("active");
    }
  }
}